<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        {{ appTitle }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Yetkili Değilsiniz ! 🔐
        </h2>
        <p class="mb-2">
          Bu sayfaya erişim izniniz yok. Dashboard'a gidin!!
        </p>
        <b-button variant="primary" class="mb-1 btn-sm-block rounded-0" @click="loginRoute()">Dashboard</b-button>
        <b-img fluid :src="imgUrl" alt="Not authorized page" />
      </div>
    </div>
  </div>
</template>

<script>
import VuexyLogo from '@core/layouts/components/Logo.vue';
import store from '@/store/index';

import { getHomeRouteForLoggedInUser } from '@/auth/utils';

export default {
  components: {
    VuexyLogo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    };
  },
  computed: {
    appTitle() {
      return process.env.VUE_APP_TITLE;
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg');
        return this.downImg;
      }
      return this.downImg;
    },
  },
  methods: {
    loginRoute() {
      this.$router.push({ name: 'dashboard' });
      // const user = JSON.parse(localStorage.getItem('userData'));
      // return getHomeRouteForLoggedInUser(user ? user.role : null);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
